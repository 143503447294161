import { Flex, Link, Text } from '@chakra-ui/react';
import React from 'react';

export const Tokenomics = () => {
	return (
		<Flex
			border='1px solid black'
			bgColor='#FFE0E0'
			borderRadius='30px'
			p='60px 45px'
			position='relative'
			gap='19px'
			flexDir='column'
			id='tokenomics'>
			<Flex flexDir='column'>
				<Text fontSize='34px' fontWeight='700'>
					Tokenomia{' '}
					<Link
						_hover={{ textDecoration: 'none' }}
						cursor='default'
						color='brandPrimary'>
						$milosc
					</Link>
				</Text>
				<Text fontSize='16px'>
					Jak dawkujemy{' '}
					<Link
						_hover={{ textDecoration: 'none' }}
						cursor='default'
						color='brandPrimary'>
						$milosc?
					</Link>
				</Text>
			</Flex>
			<Flex flexDir='column' gap='15px' fontSize='14px' color='textBlack'>
				<Flex
					w='100%'
					bgColor='white'
					align='center'
					justify='center'
					p='10px'
					borderRadius='8px'>
					<Text fontWeight='300'>
						Łączna liczba tokenów -&nbsp;
						<Link
							_hover={{ textDecoration: 'none' }}
							cursor='default'
							fontWeight='700'>
							109 105 322 111 347 263
						</Link>
					</Text>
				</Flex>
				<Flex w='100%' align='center' gap='15px' justify='space-between'>
					<Flex
						w='100%'
						bgColor='white'
						align='center'
						justify='center'
						p='10px'
						borderRadius='8px'>
						<Text fontWeight='300'>
							LP&nbsp;-&nbsp;
							<Link
								_hover={{ textDecoration: 'none' }}
								cursor='default'
								fontWeight='700'>
								40%
							</Link>
						</Text>
					</Flex>
					<Flex
						w='100%'
						bgColor='white'
						align='center'
						justify='center'
						p='10px'
						borderRadius='8px'>
						<Text fontWeight='300'>
							Treasury&nbsp;-&nbsp;
							<Link
								_hover={{ textDecoration: 'none' }}
								cursor='default'
								fontWeight='700'>
								20%
							</Link>
						</Text>
					</Flex>
					<Flex
						w='100%'
						bgColor='white'
						align='center'
						justify='center'
						p='10px'
						borderRadius='8px'>
						<Text fontWeight='300'>
							Development&nbsp;-&nbsp;
							<Link
								_hover={{ textDecoration: 'none' }}
								cursor='default'
								fontWeight='700'>
								5%
							</Link>
						</Text>
					</Flex>
					<Flex
						w='100%'
						bgColor='white'
						align='center'
						justify='center'
						p='10px'
						borderRadius='8px'>
						<Text fontWeight='300'>
							NFT&nbsp;Staking&nbsp;-&nbsp;
							<Link
								_hover={{ textDecoration: 'none' }}
								cursor='default'
								fontWeight='700'>
								15%
							</Link>
						</Text>
					</Flex>
					<Flex
						w='100%'
						bgColor='white'
						align='center'
						justify='center'
						p='10px'
						borderRadius='8px'>
						<Text fontWeight='300'>
							FMCG&nbsp;-&nbsp;
							<Link
								_hover={{ textDecoration: 'none' }}
								cursor='default'
								fontWeight='700'>
								20%
							</Link>
						</Text>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
};
