import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const brandPrimary = defineStyle({
	p: '13px 20px',
	color: 'white',
	gap: '10px',
	borderRadius: '60px',
	bg: 'brandPrimary',
	_hover: { boxShadow: '4px 5px 0px 0px #000' },
	boxShadow: '3px 4px 0px 0px #000',
	border: '1px solid black',
	fontSize: '16px',
	fontWeight: '700',
});

const brandSecondary = defineStyle({
	p: '13px 20px',
	color: 'black',
	gap: '10px',
	bg: 'white',
	borderRadius: '60px',
	_hover: { boxShadow: '4px 5px 0px 0px #000' },
	boxShadow: '3px 4px 0px 0px #000',
	border: '1px solid black',
	fontSize: '16px',
	fontWeight: '700',
});

export const buttonTheme = defineStyleConfig({
	variants: { brandPrimary, brandSecondary },
});
