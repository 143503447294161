import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';

interface IRighrSectionItemProps {
	headerText: React.ReactNode;
	content: React.ReactNode;
}

export const RightSectionItem: FC<IRighrSectionItemProps> = ({
	headerText,
	content,
}) => {
	return (
		<Flex
			position='relative'
			border='1px solid black'
			bgColor='#EAFFFA'
			borderRadius='30px'
			p='50px 40px 40px 40px'>
			<Flex
				position='absolute'
				top='0'
				left='40px'
				transform='translateY(-50%)'
				border='1px solid black'
				borderRadius='60px'
				p='15px'
				bgColor='white'>
				{headerText}
			</Flex>
			{content}
		</Flex>
	);
};
