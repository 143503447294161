import { Flex } from '@chakra-ui/react';
import React from 'react';
import { About } from './About';
import { Roadmap } from './Roadmap';
import { Tokenomics } from './Tokenomics';

export const LeftSection = () => {
	return (
		<Flex flexDir='column' gap='50px'>
			<About />
			<Roadmap />
			<Tokenomics />
		</Flex>
	);
};
