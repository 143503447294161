import { Divider, Flex, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { Stage } from './Stage';
import { StageItem } from './StageItem';

export const Roadmap = () => {
	return (
		<Flex
			border='1px solid black'
			bgColor='#F1F2FF'
			borderRadius='30px'
			p='64px 38px'
			position='relative'
			gap='19px'
			flexDir='column'
			id='roadmap'>
			<Flex flexDir='column'>
				<Text fontSize='34px' fontWeight='700'>
					<Link
						_hover={{ textDecoration: 'none' }}
						cursor='default'
						color='brandPrimary'>
						Miłosna
					</Link>
					&nbsp;historia
					{/* <Link
						_hover={{ textDecoration: 'none' }}
						cursor='default'
						color='brandPrimary'>
						miłość
					</Link> */}
				</Text>
				<Text fontSize='16px'>
					Napiszmy wspólnie historię{' '}
					<Link
						_hover={{ textDecoration: 'none' }}
						cursor='default'
						color='brandPrimary'>
						$milosc.
					</Link>
				</Text>
			</Flex>
			<Flex
				flexDir='column'
				borderRadius='8px'
				bgColor='white'
				maxH='60vh'
				overflow='auto'
				scrollSnapAlign='start'>
				<Stage
					number={0.7}
					content={
						<Flex flexDir='column' gap='12px'>
							<StageItem text='500+ ukochanych' />
							<StageItem text='Zapięcie płynności finansowej na uniswap.org' />
							<StageItem
								text='Zablokowanie tokenów LP na uncx.network'
								message='https://www.uncx.network/'
							/>
							<StageItem
								text='Dystrybucja tokenów do skarbca'
								message='https://lore.xyz/profile/milosc'
							/>
							<StageItem text='Dystrybucja tokenów dla twórców' />
							<StageItem text='$250k MCap' />
						</Flex>
					}
				/>
				<Divider />
				<Stage
					number={1.3}
					content={
						<Flex flexDir='column' gap='12px'>
							<StageItem text='1000+ ukochanych' />
							<StageItem text='$420k MCap' />
							<StageItem text='thePolacy NFT staking' />
						</Flex>
					}
				/>
				<Stage
					number={2.2}
					content={
						<Flex flexDir='column' gap='12px'>
							<StageItem text='1500+ ukochanych' />
							<StageItem
								text='Program milosc powercoin+'
								message='LP providing na uniswap, reflink do ogłoszenia z Twittera odnośnie programu dla LP providerów tokenu $milosc'
							/>
							<StageItem text='Zapięcie płynności na CEX' />
							<StageItem text='$1 000 000 MCap' />
							<StageItem
								text='On-chain governance'
								message='http://tally.xyz/'
							/>
							<StageItem
								text='Newsletter dla właścicieli tokenu $milosc'
								message='Dedykowana grupa telegram dla właścicieli tokenu $milosc (guild.xyz)'
							/>
							<StageItem text='Niespodzianka' />
						</Flex>
					}
				/>
				<Stage
					number={3.1}
					content={
						<Flex flexDir='column' gap='12px'>
							<StageItem text='3000+ ukochanych' />
							<StageItem text='Produkacja wegańskich, zero-waste dóbr z sektora FMCG' />
							<StageItem text='Dystrybucja produktów FMCG w znanych polskich sieciach handlowych' />
							<StageItem text='$milosc improvement proposal 1.0' />
							<StageItem text='Transformacja w DAO' />
						</Flex>
					}
				/>
			</Flex>
		</Flex>
	);
};
