import { Flex } from '@chakra-ui/react';
import React from 'react';
import '@fontsource/sora';
import '@fontsource/sora/800.css';
import '@fontsource/sora/700.css';
import '@fontsource/sora/300.css';

export const PageWrapper = ({ children }: { children: React.ReactNode }) => {
	return (
		<Flex
			maxW='1200px'
			w={{ base: 'calc(100vw - 64px)', xl: '100%' }}
			mx='auto'
			zIndex='0'
			flexDir='column'
			position='relative'
			flexWrap='wrap'
			fontFamily='Sora'
			overflow='hidden'>
			{children}
		</Flex>
	);
};
