import { Flex, Image, Tooltip } from '@chakra-ui/react';
import React, { FC } from 'react';

interface IStageItemProps {
	text: string;
	done?: boolean;
	message?: string;
}

export const StageItem: FC<IStageItemProps> = ({ done, text, message }) => {
	return (
		<Flex
			align='center'
			gap='10px'
			fontSize='14px'
			color={done ? 'textBlack' : 'rgba(0, 0, 0, 0.5)'}>
			<Tooltip
				label={message}
				closeDelay={500}
				p='5px 10px'
				bgColor='white'
				border='1px solid black'
				borderRadius='15px'
				color='textBlack'>
				{done ? (
					<Image src='/assets/icons/heart-green.svg' cursor='pointer' />
				) : (
					<Image src='/assets/icons/heart-gray.svg' cursor='pointer' />
				)}
			</Tooltip>
			{text}
		</Flex>
	);
};
