import { Flex, Image } from '@chakra-ui/react';
import React from 'react';
import { Navlink } from './Navlink';
import { Socials } from './Socials';

export const Navbar = () => {
	return (
		<Flex
			w='100%'
			m='50px 0'
			bgColor='white'
			borderRadius='30px'
			boxShadow='0px 5px 11px 0px rgba(0, 0, 0, 0.15)'
			p='38px 44px'
			justify='space-between'>
			<Image
				src='/assets/icons/logo-black.svg'
				cursor='pointer'
				onClick={() => {
					window.scrollTo({ behavior: 'smooth', top: 0 });
				}}
				w={{ base: '70px', sm: '100px', md: '150px', lg: '228px' }}
			/>
			<Flex gap={{ sm: '25px', md: '40px', lg: '55px' }} align='center'>
				<Flex align='center' gap={{ sm: '12px', md: '25px', lg: '33px' }}>
					<Navlink location='love' text='Czym jest miłość?' />
					<Navlink location='roadmap' text='Roadmap' />
					<Navlink location='tokenomics' text='Tokenomia' />
					<Navlink location='' text='Kup $milosc' />
					<Navlink location='' text='Zobacz wykres' />
				</Flex>
				<Socials />
			</Flex>
		</Flex>
	);
};
