import { Button, Flex, Image, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { RightSectionItem } from '../shared/RightSectionItem';

export const RightSection = () => {
	return (
		<Flex flexDir='column' gap='50px'>
			<RightSectionItem
				content={
					<Text fontSize='14px'>
						<Link
							_hover={{ textDecoration: 'none' }}
							cursor='default'
							fontWeight='700'>
							Bo tak.
						</Link>
						&nbsp;I nic lepszego, ktokolwiek wymyślić nie może.
					</Text>
				}
				headerText={
					<Text fontSize='17px' fontWeight='700' color='#353535'>
						Dlaczego&nbsp;
						<Link
							_hover={{ textDecoration: 'none' }}
							cursor='default'
							color='brandPrimary'>
							miłość?
						</Link>
					</Text>
				}
			/>
			<RightSectionItem
				content={
					<Flex flexDir='column' gap='26px' align='flex-start'>
						<Text fontSize='14px'>
							Miłość można jedynie otrzymać, przynajmniej tę prawdziwą.
							Bezwarunkowo.{' '}
							<Link
								_hover={{ textDecoration: 'none' }}
								cursor='default'
								fontWeight='700'>
								Bo tak.
							</Link>
							<br />
							<br />
							Nasz token{' '}
							<Link
								_hover={{ textDecoration: 'none' }}
								cursor='default'
								color='brandPrimary'
								fontWeight='700'>
								$milosc
							</Link>{' '}
							jest dostępny wewnątrz ekosystemu Uniswap.
						</Text>
						<Button variant='brandPrimary'>
							Kup $milosc <Image src='/assets/icons/arrow-right.svg' />
						</Button>
					</Flex>
				}
				headerText={
					<Text fontSize='17px' fontWeight='700' color='#353535'>
						Jak kupić&nbsp;
						<Link
							_hover={{ textDecoration: 'none' }}
							cursor='default'
							color='brandPrimary'>
							miłość?
						</Link>
					</Text>
				}
			/>
			<RightSectionItem
				content={
					<Text fontSize='14px'>
						Token{' '}
						<Link
							_hover={{ textDecoration: 'none' }}
							cursor='default'
							fontWeight='700'
							color='brandPrimary'>
							$milosc
						</Link>{' '}
						jest po prostu tokenem społecznościowym, krypto-amuletem i
						power-coinem w jednym.
						<Link
							_hover={{ textDecoration: 'none' }}
							cursor='default'
							fontWeight='700'>
							<br />
							<br />
							Inwestując w miłość
						</Link>
						, inwestujesz w siebie.
						<br />
						Inwestując w siebie,{' '}
						<Link
							_hover={{ textDecoration: 'none' }}
							cursor='default'
							fontWeight='700'>
							inwestujesz w swoje otoczenie.
						</Link>
					</Text>
				}
				headerText={
					<Text fontSize='17px' fontWeight='700' color='#353535'>
						Utility tokenu&nbsp;
						<Link
							_hover={{ textDecoration: 'none' }}
							cursor='default'
							color='brandPrimary'>
							miłość
						</Link>
					</Text>
				}
			/>
		</Flex>
	);
};
