import { Flex, Text } from '@chakra-ui/react';
import React, { FC } from 'react';

interface IStageProps {
	number: number;
	content: React.ReactNode;
}

export const Stage: FC<IStageProps> = ({ number, content }) => {
	return (
		<Flex flexDir='column' gap='12px' p='30px 40px'>
			<Text fontSize='16px' fontWeight='700' color='brandPrimary'>
				Etap {number}
			</Text>
			{content}
		</Flex>
	);
};
