import { Flex, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineTwitter } from 'react-icons/ai';
import { PageWrapper } from '../shared/wrappers/PageWrapper';

export const Footer = () => {
	return (
		<Flex w='100%' bgColor='black' py='50px'>
			<PageWrapper>
				<Flex flexDir='column' gap='30px'>
					<Flex w='100%' justify='space-between' align='center' color='white'>
						<Flex flexDir='column' fontWeight='700'>
							<Text fontSize={{ base: '16px', md: '1.2vw' }}>
								VIRAL PUBLIC LICENSE
							</Text>
							<Text fontSize={{ base: '12px', md: '1vw' }}>
								All Rights Reversed © 2020-2023
							</Text>
						</Flex>
						<Flex
							gap={{ base: '16px', md: '1.2vw' }}
							fontWeight='700'
							align='center'>
							<Text fontSize={{ base: '16px', md: '1.2vw' }}>
								Jesteśmy tutaj:{' '}
							</Text>
							<Link href='https://www.twitter.com/milosc_xyz' target='_blank'>
								<AiOutlineTwitter size='20px' />
							</Link>
						</Flex>
					</Flex>
					<Flex
						flexDir='column'
						gap={{ base: '10px', md: '0.7vw' }}
						fontSize={{ base: '12px', md: '0.8vw' }}
						color='#636363'>
						<Text>
							This WORK is hereby relinquished of all associated ownership,
							attribution and copy rights, and redistribution or use of any
							kind, with or without modification, is permitted without
							restriction subject to the following conditions:
						</Text>
						<Text>
							1. Redistributions of this WORK, or ANY work that makes use of ANY
							of the contents of this WORK by ANY kind of copying, dependency,
							linkage, or ANY other possible form of DERIVATION or COMBINATION,
							must retain the ENTIRETY of this license. <br />
							2.No further restrictions of ANY kind may be applied.
						</Text>
						<Text mt={{ base: '16px', md: '1.2vw' }}>
							Fully permissive, viral software license. The VPL is designed to
							achieve and extend the GPL's strong copyleft without the burden of
							its obligations and restrictions. The VPL's sole restriction is
							its own viral continuity, allowing it to effectively and
							permanently infect any work it touches with absolute
							permissiveness.
						</Text>
					</Flex>
				</Flex>
			</PageWrapper>
		</Flex>
	);
};
