import { extendTheme } from '@chakra-ui/react';
import { colors } from './colors';
import { buttonTheme } from './components/Button';
import { globalStyles } from './global';

export const theme = extendTheme({
	colors: colors,
	components: { Button: buttonTheme },
	config: {
		initialColorMode: 'light',
		useSystemColorMode: false,
	},
	fonts: {
		body: `Sora`,
	},
	styles: {
		...globalStyles,
	},
});
