import { Flex, Image, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { PageWrapper } from '../shared/wrappers/PageWrapper';

export const TopBanner = () => {
	return (
		<Flex
			w='100vw'
			p='17px 0'
			justify='space-between'
			align='center'
			bgColor='backgroundSecondary'
			borderBottom='1px solid #84B1AE'>
			<PageWrapper>
				<Flex align='center' justify='space-between' gap='10px' w='100%'>
					<Flex align='center' gap='12px'>
						<Image src='/assets/icons/bell.svg' />
						<Text fontSize={{ base: '10px', lg: '14px' }} fontWeight='800'>
							Bądź częścią czegoś wielkiego, nie czekaj i kup $milosc!
						</Text>
					</Flex>
					<Text fontSize={{ base: '10px', lg: '14px' }}>
						Token bedzie do kupienia 01.08.2023
					</Text>
					<Flex align='center' gap={{ base: '10px', lg: '21px' }}>
						<Text fontSize={{ base: '10px', lg: '14px' }} fontWeight='800'>
							Nie przegap okazji&nbsp;
							<Link
								fontWeight='400'
								_hover={{ textDecoration: 'none' }}
								cursor='default'>
								i dołącz do naszego&nbsp;
							</Link>
							<Link
								textDecoration='underline'
								href='https://t.me/+d9HJ0iE9ecw0ZGRk'
								target='_blank'>
								telegrama!
							</Link>
						</Text>
						<Image src='/assets/icons/heart-red.svg' />
					</Flex>
				</Flex>
			</PageWrapper>
		</Flex>
	);
};
