import { Button, Flex, Image, Link, Text } from '@chakra-ui/react';
import React from 'react';

export const About = () => {
	return (
		<Flex
			border='1px solid black'
			bgColor='#FFF5DB'
			borderRadius='30px'
			p='50px'
			position='relative'
			gap='38px'
			justify='flex-start'
			align='flex-start'
			id='love'>
			<Image
				borderRadius='30px'
				maxW='256px'
				border='1px solid black'
				src='https://ipfs.io/ipfs/QmeaYvyXF8Zm98V48TnxqdKZikrkncSkMn2E6EfQF15E4h/789.png'
				mt='-23px'
				ml='-23px'
			/>
			<Flex flexDir='column' gap='15px' align='flex-start'>
				<Flex flexDir='column' fontSize='34px' color='black' fontWeight='700'>
					<Text>Czym jest token</Text>
					<Text color='brandPrimary'>$milosc?</Text>
				</Flex>
				<Text fontSize='14px' fontWeight='300'>
					Token miłość to odpowiedź web3 na narastające ciśnienie w
					krypto-sferze. Stojąc w opozycji do nienawiści, kochamy to co robimy i
					robimy to co kochamy.
				</Text>
				<Text fontSize='14px' fontWeight='700'>
					Zanim nas obrazisz, zrozum, że obrażasz tym samym samego siebie.
				</Text>
				<Text fontSize='14px' fontWeight='300' mt='10px'>
					Promujemy miłość, przyjaźń, dobro, piękno i sztukę. Rozumiemy, że
					temat jest kontrowersyjny. Kochamy darmowy marketing i{' '}
					<Link
						_hover={{ textDecoration: 'none' }}
						cursor='default'
						//color='brandPrimary'
						fontWeight='700'>
						shill
					</Link>
					, więc obrażaj, ale z sensem.
				</Text>
				<Text fontSize='14px' fontWeight='700'>
					My już Cię kochamy.
				</Text>
				<Button variant='brandSecondary' mt='20px'>
					Zobacz wykres <Image src='/assets/icons/bar-chart.svg' />
				</Button>
			</Flex>
		</Flex>
	);
};
