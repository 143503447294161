import React from 'react';
import { Flex, Link } from '@chakra-ui/react';
//import { AiOutlineInstagram } from 'react-icons/ai';
import { AiOutlineTwitter } from 'react-icons/ai';

export const Socials = () => {
	return (
		<Flex align='center' gap='15px'>
			{/* <Link>
				<AiOutlineInstagram size='20px' />
			</Link> */}
			<Link href='https://www.twitter.com/milosc_xyz' target='_blank'>
				<AiOutlineTwitter size='20px' />
			</Link>
			{/* <Link>
				<Image src='/assets/icons/tiktok-logo.svg' />
			</Link> */}
		</Flex>
	);
};
