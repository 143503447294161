import * as React from 'react';
import {
	Box,
	Button,
	ChakraProvider,
	Flex,
	Image,
	Link,
	Text,
} from '@chakra-ui/react';
import { theme } from './theme/theme';
import { PageWrapper } from './components/shared/wrappers/PageWrapper';
import { TopBanner } from './components/global/TopBanner';
import { Navbar } from './components/global/navbar/Navbar';
import { Header } from './components/common/Header';
import { RightSection } from './components/common/RightSection';
import { LeftSection } from './components/common/leftSection/LeftSection';
import { Footer } from './components/global/Footer';
import { RightSectionItem } from './components/shared/RightSectionItem';

export const App = () => {
	const [scroll, setScroll] = React.useState<number>(0);

	React.useEffect(() => {
		window.addEventListener('scroll', () => setScroll(window.scrollY), {
			passive: true,
		});
	}, []);

	return (
		<ChakraProvider theme={theme}>
			<Box
				display={{ base: 'none', md: 'block' }}
				bgImage='/assets/icons/bgHearts.svg'>
				<TopBanner />
				<Flex
					position='sticky'
					zIndex='1000'
					top={scroll > 30 ? '-10px' : '50px'}
					transition='all 0.5s ease-in-out'>
					<Flex
						position='absolute'
						w='100%'
						h='100%'
						top='0'
						left='0'
						bg='rgba(255, 255, 255, 0)'
						backdropFilter={scroll > 30 ? 'blur(10px)' : 'blur(0)'}
						style={{
							maskImage:
								'linear-gradient(to bottom,  rgba(255, 255, 255, 1) 0%, transparent 100%)',
							WebkitMaskImage:
								'linear-gradient(to bottom,  rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.7) 90%, transparent 100%)',
						}}></Flex>
					<PageWrapper>
						<Navbar />
					</PageWrapper>
				</Flex>
				<PageWrapper>
					<Header />
					<Flex
						my='50px'
						gap='30px'
						flexDir={{ base: 'column', lg: 'row' }}
						maxW='100%'>
						<LeftSection />
						<RightSection />
					</Flex>
				</PageWrapper>
				<Footer />
			</Box>
			<Flex
				display={{ base: 'flex', md: 'none' }}
				flexDir='column'
				gap='24px'
				p='50px 5vw'
				w='100%'
				align='center'
				overflow='hidden'
				bgImage='/assets/icons/bgHearts.svg'
				minH='100vh'>
				<Flex
					borderRadius='30px'
					bgColor='white'
					w='90vw'
					justify='center'
					align='center'
					p='24px'
					boxShadow='0px 5px 11px 0px rgba(0, 0, 0, 0.15)'>
					<Image
						src='/assets/icons/logo-black.svg'
						cursor='pointer'
						onClick={() => {
							window.scrollTo({ behavior: 'smooth', top: 0 });
						}}
					/>
				</Flex>
				<Flex mt='100px'>
					<RightSectionItem
						content={
							<Flex flexDir='column' gap='16px' align='flex-start'>
								<Text fontSize='14px'>
									W celu zachowania najwyższej jakości{' '}
									<Link
										_hover={{ textDecoration: 'none' }}
										cursor='default'
										color='brandPrimary'
										fontWeight='700'>
										miłości
									</Link>
									, zapraszamy na wielki format.
								</Text>
								<Text fontSize='14px'>
									Strona dostępna tylko w wersji desktop.
								</Text>
								<Text fontSize='14px'>Inwestuj w miłość.</Text>
								<Button variant='brandPrimary' mt='10px'>
									Kup $milosc <Image src='/assets/icons/arrow-right.svg' />
								</Button>
							</Flex>
						}
						headerText={
							<Text fontSize='17px' fontWeight='700' color='#353535'>
								Jak kupić&nbsp;
								<Link
									_hover={{ textDecoration: 'none' }}
									cursor='default'
									color='brandPrimary'>
									miłość?
								</Link>
							</Text>
						}
					/>
				</Flex>
			</Flex>
		</ChakraProvider>
	);
};
