import { Text } from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';

interface INavlinkProps {
	location: string;
	text: string;
}

export const Navlink: FC<INavlinkProps> = ({ location, text }) => {
	const [element, setElement] = useState<HTMLElement | null>();

	useEffect(() => {
		setElement(document.getElementById(location ? location : ''));
	}, [location]);

	const scrollIntoViewWithOffset = (offset: number) => {
		window.scrollTo({
			behavior: 'smooth',
			top:
				element!.getBoundingClientRect().top -
				document.body.getBoundingClientRect().top -
				offset,
		});
	};

	return (
		<Text
			fontSize={{ base: '10px', lg: '14px' }}
			fontWeight='500'
			color='textBlack'
			cursor='pointer'
			onClick={() => {
				scrollIntoViewWithOffset(150);
			}}>
			{text}
		</Text>
	);
};
