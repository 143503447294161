import { Button, Flex, Image, Link, Text } from '@chakra-ui/react';
import React from 'react';

export const Header = () => {
	return (
		<Flex
			position='relative'
			bgGradient='linear(180deg, #FFEAEA 0%, #FFC2C2 100%)'
			p='137px 40px 121px 40px'
			justify='center'
			borderRadius='30px'
			border='1px solid black'
			flexDir='column'
			gap='50px'
			overflow='hidden'
			w='100%'>
			<Image
				position='absolute'
				bottom='0'
				right='0'
				src='/assets/icons/header-milosc.svg'
				zIndex='1'
			/>
			<Image
				position='absolute'
				top='0'
				left='0'
				src='/assets/icons/heart-big-bg.svg'
				zIndex='1'
			/>
			<Image
				position='absolute'
				bottom='170px'
				right='170px'
				src='/assets/icons/heart-mid-bg.svg'
				zIndex='1'
			/>
			<Flex flexDir='column' zIndex='5'>
				<Text fontSize='24px' fontWeight='800'>
					Pierwszy taki w Polsce
				</Text>
				<Text fontSize='62px' fontWeight='700'>
					Inwestuj w{' '}
					<Link
						color='brandPrimary'
						_hover={{ textDecoration: 'none' }}
						cursor='default'>
						Miłość!
					</Link>
				</Text>
			</Flex>
			<Flex gap='12px' align='center' zIndex='5'>
				<Button variant='brandSecondary'>
					Zobacz wykres <Image src='/assets/icons/bar-chart.svg' />
				</Button>
				<Button variant='brandPrimary'>
					Kup $milosc <Image src='/assets/icons/arrow-right.svg' />
				</Button>
			</Flex>
		</Flex>
	);
};
